import { h } from 'preact';
import { useState, useCallback } from 'preact/compat';
import { Router } from 'preact-router';
import { Provider as PrerenderProvider } from '@preact/prerender-data-provider';

import { PreviewContext } from '../lib/context';
import useViewportHeight from '../lib/use-vh';

import Canvas from './canvas';
import Header from './Header';
import Footer from './Footer';
import CookieBar from './CookieBar';

// Code-splitting is automated for routes
import About from '../routes/about';
import Contact from '../routes/contact';
import Home from '../routes/home';
import Legal from '../routes/legal';
import Ventures from '../routes/ventures';
import Preview from '../routes/preview';

export default function App(props) {
	useViewportHeight();

	const [previewToken, setPreviewToken] = useState(null);

	// TODO handle slogan via store or context
	const [slogan, setSlogan] = useState(null);

	const handleRoute = useCallback(route => {
		if (typeof window !== 'undefined') {
			window.scroll(0, 0);

			if (typeof window.ga === 'function') {
				setTimeout(() => {
					window.ga('send', 'pageview', route.url);
				});
			}
		}
	}, []);

	return (
		<PreviewContext.Provider value={previewToken}>
			<PrerenderProvider value={props}>
				<div id="app">
					<Canvas />
					<Header slogan={slogan} />
					<main>
						<Router onChange={handleRoute}>
							<About path="/about" setSlogan={setSlogan} />
							<Contact path="/contact" setSlogan={setSlogan} />
							<Home path="/" setSlogan={setSlogan} />
							<Legal path="/legal/:uid" setSlogan={setSlogan} />
							<Ventures path="/ventures" setSlogan={setSlogan} />
							<Preview path="/preview" setPreviewToken={setPreviewToken} />
						</Router>
					</main>
					<Footer />
					<CookieBar />
				</div>
			</PrerenderProvider>
		</PreviewContext.Provider>
	);
}
