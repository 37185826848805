function fract(x) {
	return x % 1;
}

function rand(x) {
	return fract(Math.sin(x * 43758.5453123));
}

function mix(x, y, a) {
	 return x * (1 - a) + y * a;
}

function clamp(x, min, max) {
	return Math.max(min, Math.min(max, x));
}

function smoothstep(edge0, edge1, x) {
	const t = clamp((x - edge0) / (edge1 - edge0), 0.0, 1.0);
	return t * t * (3.0 - 2.0 * t);
}

export function noise(x) {
	const i = Math.floor(x);
	const f = fract(x);
	return mix(rand(i), rand(i + 1), smoothstep(0,1,f));
}
