import { h } from 'preact';
import { RichText } from 'prismic-reactjs';
import Ellipse from '../../components/ellipse'; // TODO
import style from './Grid.css';

export default function Grid(props) {
	return (
		<section class={style.grid}>
			{props && props.items && props.items.map(item => (
				<article>
					<h3>{RichText.asText(item.heading)}</h3>
					<RichText render={item.text} />
				</article>
			))}
		</section>
	);
}
