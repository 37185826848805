import { h, Fragment, createContext } from 'preact';
import { useState, useEffect, useMemo, useCallback } from 'preact/hooks';
import { useTransition, animated, config } from 'react-spring';
import { useContent } from '../lib/content';
import Modal from './Modal';
import style from './Page.css';

export const ModalContext = createContext(null);

export default function createPage({ slices, modal }) {
	return function Page(props) {
		const [data/*, loading, error*/] = useContent(props);

		useEffect(() => {
			if (data) {
				const slogan =
					data &&
					data.document &&
					data.document.data &&
					data.document.data.slogan;

				props.setSlogan(slogan);
			}
		}, [data]);

		const blocks = useMemo(() => {
			const body =
				data &&
				data.document &&
				data.document.data &&
				data.document.data.body;

			if (!body) {
				return [];
			}

			let index = 0;

			return body.reduce((accumulator, slice) => {
				const currentBlock = accumulator[
					accumulator.length - 1
				] || {};

				const {
					container = Fragment,
					component
				} = slices[slice.slice_type];

				const item = {
					component,
					props: slice
				};

				if (
					container !== Fragment &&
					currentBlock.container === container
				) {
					currentBlock.slices.push(item);
				}
				else {
					accumulator.push({
						key: index++,
						container,
						slices: [item]
					});
				}

				return accumulator;
			}, []);
		}, [data]);

		const modalData = useMemo(() => {
			const document = data
				&& data.document;

			if (!document) return null;
			if (!modal) return null;
			if (!modal.getData) return null;

			return modal.getData(document);
		}, [data]);

		const [modalOpen, setModalOpen] = useState(false);
		const [openModalKey, setOpenModalKey] = useState(null);

		const openModal = useCallback(key => {
			setOpenModalKey(key);
			setModalOpen(true);
		});

		const openModalData = useMemo(() => modalData && modalData.find(
			item => modal.getKey(item) === openModalKey
		), [modalData, openModalKey]);

		const dismissModal = useCallback(() => setModalOpen(false), []);

		function skip(amount) {
			if (!modal) return;
			if (!modalData) return;
			if (!openModalKey) return;

			const index = modalData.findIndex(
				item => modal.getKey(item) === openModalKey
			);

			const length = modalData.length;
			const nextIndex = (((index + amount) % length) + length) % length;
			const nextKey = modal.getKey(modalData[nextIndex]);

			setOpenModalKey(nextKey);
		}

		const gotoPrevious = useCallback(() => skip(-1), [modalData, openModalKey]);
		const gotoNext = useCallback(() => skip(1), [modalData, openModalKey]);

		function handleKeyDown(event) {
			const KEY_ESCAPE = 27;
			const KEY_LEFT = 37;
			const KEY_RIGHT = 39;

			switch (event.keyCode) {
				case KEY_ESCAPE:
					return dismissModal();
				case KEY_LEFT:
					return gotoPrevious();
				case KEY_RIGHT:
					return gotoNext();
			}
		}

		useEffect(() => {
			window.addEventListener('keydown', handleKeyDown);
			return () => {
				window.removeEventListener('keydown', handleKeyDown);
			};
		}, [modalData, openModalKey]);

		const blockTransitions = useTransition(blocks, item => item.key, {
			from: { opacity: 0, transform: 'translate3d(0,100px,0)' },
			enter: { opacity: 1, transform: 'translate3d(0,0,0)' },
			leave: { opacity: 0, transform: 'translate3d(0,0,0)' },
			config: config.molasses
		});

		return (
			<ModalContext.Provider value={openModal}>
				<div class={style.page}>
					{blockTransitions.map(({ item, props, key }) => (
						<animated.div key={key} style={props}>
							<item.container>
								{item.slices.map(slice => (
									<slice.component {...slice.props} />
								))}
							</item.container>
						</animated.div>
					))}
				</div>

				{modal && modal.component && (
					<Modal
						open={modalOpen}
						onDismiss={dismissModal}
						onNext={gotoNext}
						onPrevious={gotoPrevious}
					>
						<modal.component {...openModalData} />
					</Modal>
				)}
			</ModalContext.Provider>
		);
	};
}
