import { h } from 'preact';
import { useCallback } from 'preact/hooks';
import { RichText } from 'prismic-reactjs';
import style from './FoundersNav.css';

function FoundersNavItem(props) {
	const handleClick = useCallback(event => {
		const slug = props.founder
			&& props.founder.uid;

		if (!slug) {
			return;
		}

		const element = document.getElementById(slug);

		if (!element) {
			return;
		}

		const { top, height } = element.getBoundingClientRect();
		const offset = window.scrollY + top - window.innerHeight / 2 + height / 2;

		event.preventDefault();

		window.scroll({
			top: offset,
			left: 0,
			behavior: 'smooth'
		});
	}, [props.founder.uid]);

	return (
		<li data-active={props.index === props.activeIndex} onClick={handleClick}>
			<a href={`#${props.founder.uid}`}>
				<p>{RichText.asText(props.founder.data.name)}</p>
				<p>{props.founder.data.role}</p>
			</a>
		</li>
	);
}

export default function FoundersNav(props) {
	return (
		<nav class={style.nav}>
			<h3>Our founders</h3>
			<ul>
				{props.items.map(({ founder }, index) => (
					<FoundersNavItem
						founder={founder}
						activeIndex={props.activeIndex}
						index={index}
					/>
				))}
			</ul>
		</nav>
	);
}
