import { h } from 'preact';
import Button from './Button';
import style from './TeamTeaser.css';

export default function TeamTeaser() {
	return (
		<section class={style.team}>
			<aside>
				<img src="../../assets/bonum_teaser.jpg" alt="Our team" />
			</aside>
			<article>
				<h1>Meet our team</h1>
				<p>
					We assemble a diverse team of entrepreneurial minds. Get to know them.
				</p>
				<p>
					<Button href="/about">Learn more <span class="visually-hidden">about the team</span></Button>
				</p>
			</article>
		</section>
	);
}
