import { useState, useEffect, useRef } from 'preact/compat';
import { useLocation } from '../../lib/router';

import {
	Color,
	Geometry,
	Mesh,
	Program,
	Renderer
} from 'ogl';

import { color } from 'd3-color';
import anime from 'animejs';

import vertex from './vertex.glsl';
import fragment from './fragment.glsl';

const colors = [
	{
		pattern: /^\/$/,
		foregroundColor: '#5c6f5e',
		backgroundColor: '#49575b'
	},
	{
		pattern: /^\/ventures\/?$/,
		foregroundColor: '#bec0c1',
		backgroundColor: '#cab197'
	},
	{
		pattern: /^\/about\/?$/,
		foregroundColor: '#edecec',
		backgroundColor: '#cdcecf'
	},
	{
		pattern: /^\/contact\/?$/,
		foregroundColor: '#edecec',
		backgroundColor: '#49575b'
	},
	{
		pattern: /^\/legal(\/.+)?\/?$/,
		foregroundColor: '#ffffff',
		backgroundColor: '#ffffff'
	},
	{
		// fallback
		pattern: /[\s\S]*/,
		foregroundColor: '#ffffff',
		backgroundColor: '#ffffff'
	}
];

function getColors(pathname) {
	for (const color of colors) {
		if (color.pattern.test(pathname)) {
			return color;
		}
	}
}

export default function useCanvas() {
	const location = useLocation(null);
	const canvasRef = useRef(null);
	const rendererRef = useRef(null);
	const programRef = useRef(null);
	const colorsRef = useRef({});
	const [initialized, setInitialized] = useState(false);

	// Set up canvas
	useEffect(() => {
		const canvas = canvasRef.current;
		const width = window.innerWidth;
		const height = window.innerHeight;
		const dpr = window.devicePixelRatio || 1;

		const renderer = rendererRef.current = new Renderer({
			canvas,
			width,
			height,
			dpr,
			alpha: true
		});

		const gl = renderer.gl;

		gl.clearColor(1, 1, 1, 0);

		const geometry = new Geometry(gl, {
			position: { size: 2, data: new Float32Array([-1, -1, 3, -1, -1, 3]) },
			uv: { size: 2, data: new Float32Array([0, 0, 2, 0, 0, 2]) }
		});

		const initialColors = getColors(location.pathname);

		colorsRef.current.foregroundColor = initialColors.foregroundColor;
		colorsRef.current.backgroundColor = initialColors.foregroundColor;

		const program = programRef.current = new Program(gl, {
			vertex,
			fragment,
			uniforms: {
				uTime: { value: 0 },
				uForegroundColor: { value: new Color(colorsRef.current.foregroundColor) },
				uBackgroundColor: { value: new Color(colorsRef.current.backgroundColor) }
			},
			transparent: true
		});

		const scene = new Mesh(gl, {
			geometry,
			program
		});

		// let id = requestAnimationFrame(tick);

		// Chrome Lighthouse thinks the movement in the header is due
		// to loading activity and penalises the site. Wait a bit before
		// starting the render loop
		let id;
		setTimeout(() => {
			id = requestAnimationFrame(tick);
			setInitialized(true);
		}, 0);

		return () => cancelAnimationFrame(id);

		function tick(t) {
			id = requestAnimationFrame(tick);
			program.uniforms.uTime.value = t * 0.001;
			renderer.render({ scene });
		}
	}, []);

	// Resize canvas when window is resized
	useEffect(() => {
		window.addEventListener('resize', resize, false);
		return () => window.removeEventListener('resize', resize, false);

		function resize() {
			const renderer = rendererRef.current;
			renderer.setSize(window.innerWidth, window.innerHeight);
		}
	}, []);

	// Transition to new colors when location changes
	useEffect(() => {
		if (!initialized) {
			return;
		}

		const program = programRef.current;
		const targetColors = getColors(location.pathname);

		anime({
			targets: colorsRef.current,
			easing: 'easeInCubic',
			foregroundColor: targetColors.foregroundColor,
			backgroundColor: targetColors.backgroundColor,
			update() {
				// Anime turns interpolated colors into rgba values, and OGL expects hex
				const foregroundColor = color(colorsRef.current.foregroundColor).formatHex();
				const backgroundColor = color(colorsRef.current.backgroundColor).formatHex();

				program.uniforms.uForegroundColor.value = new Color(foregroundColor);
				program.uniforms.uBackgroundColor.value = new Color(backgroundColor);
			}
		});
	}, [initialized, location]);

	return {
		canvas: canvasRef,
		color: getColors(location.pathname).foregroundColor
	};
}
