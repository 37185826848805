import { h } from 'preact';
import style from './Logo.css';

export default function Logo(props) {
	return (
		<svg class={style.logo} viewBox="0 0 40 50" {...props}>
			<path d="M25.6932 19.1838H25.2334C30.5473 19.1838 36.27 16.5818 36.27 9.64298C36.2189 3.31645 31.3648 0.00012207 24.0582 0.0511425H22.3209L1.16735 0.00012207C0.145438 0.00012207 -0.365518 1.22461 0.298725 1.9389L1.26954 2.7042C3.10898 4.13278 4.18199 6.27563 4.18199 8.57155L4.23309 41.4797C4.23309 43.5716 3.36446 45.5613 1.83159 46.9899L0.605298 48.1124C0.0432466 48.8777 0.605298 50.0001 1.57611 50.0001H15.5252C15.5252 50.0001 21.2479 50.0001 24.2115 50.0001H24.2626C32.7955 50.0001 40 45.9695 40 33.6226C40 23.6226 33.562 19.1838 25.6932 19.1838ZM19.9705 46.1736C17.3136 46.1736 15.5252 44.694 15.5252 42.0409V41.4797V27.296V25.0001V21.5818V18.9797V7.85726V7.04094C15.5252 5.05114 17.1092 3.46951 19.1019 3.46951C22.3209 3.31645 26.5108 3.77563 26.5108 10.2552C26.5108 17.6532 20.4815 18.0103 18.1311 18.2654C18.1311 18.2654 17.1092 18.3164 17.1092 19.4389C17.1092 20.0001 17.6712 20.4593 18.08 20.5103C20.6348 20.7654 27.7882 19.5409 27.7882 33.3675C27.8392 46.9899 19.9705 46.1736 19.9705 46.1736Z" fill="currentColor" />
		</svg>
	);
}
