import { useState, useContext } from 'preact/compat';
import { usePrerenderData } from '@preact/prerender-data-provider';
import { RichText } from 'prismic-reactjs';
import { PreviewContext } from './context';

export function useContent(props) {
	const [state, setState] = useState({
		value: null,
		isLoading: false,
		error: false
	});
	const prerenderData = usePrerenderData(props);
	const previewToken = useContext(PreviewContext);

	async function fetchPreviewData() {
		setState({
			value: null,
			isLoading: true,
			error: false
		});

		try {
			const { fetchPreview } = await import('./fetch-preview');
			const document = await fetchPreview(props, previewToken);

			setState({
				value: {
					url: props.url,
					document
				},
				isLoading: false,
				error: false
			});
		}
		catch (e) {
			setState({
				value: null,
				isLoading: false,
				error: e
			});
		}
	}

	if (previewToken) {
		if (!state.value && !state.isLoading && !state.error) {
			fetchPreviewData();
		}
		return [state.value, state.isLoading, state.error];
	}

	// Set document title from prerender data
	if (
		prerenderData[0] && // value
		prerenderData[0].document && // value.document
		!prerenderData[1] && // isLoading
		!prerenderData[2] // error
	) {
		const doc = prerenderData[0].document;

		let title = (doc && doc.data && doc.data.title)
			? RichText.asText(doc.data.title)
			: 'Bonum';

		if (!/^Bonum$/i.test(title)) {
			title = `${title} | Bonum`;
		}

		if (typeof window !== 'undefined') {
			document.title = title;
		}
	}

	return prerenderData;
}
