import { h } from 'preact';
import { useCallback, useContext } from 'preact/hooks';
import { ModalContext } from '../../components/Page';
import VentureCard from '../../components/VentureCard';
import style from './VenturesList.css';

export default function VenturesList(props) {
	return (
		<section class={style.ventures}>
			<ul class={style.grid}>
				{props.items.map(item => item && item.venture && (
					<VenturesListItem {...item.venture} />
				))}
			</ul>
		</section>
	);
}

function VenturesListItem(props) {
	const setModal = useContext(ModalContext);
	const openModal = useCallback(() => setModal(props.uid), [props.uid]);

	return (
		<li class={style.venturesListItem} onClick={openModal} data-hover="true">
			<VentureCard {...props.data} />
		</li>
	);
}
