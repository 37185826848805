import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';
import Swiper from 'react-id-swiper';
import FounderCardMobile from './FounderCardMobile';
import style from './FoundersCarousel.css';

export default function FoundersCarousel(props) {
	const [swiper, updateSwiper] = useState(null);

	useEffect(() => {
		if (swiper) {
			setTimeout(() => swiper.update());
		}
	}, [swiper]);

	return (
		<div class={style.foundersCarousel}>
			<Swiper
				getSwiper={updateSwiper}
				slidesPerView={1}
				breakpoints={{
					768: {
						slidesPerView: 2
					},
					1200: {
						slidesPerView: 3
					}
				}}
			>
				{props.items.map(({ founder }) => (
					<div>
						<FounderCardMobile {...founder} />
					</div>
				))}
			</Swiper>
		</div>
	);
}
