import { h } from 'preact';
import style from './Logotype.css';

export default function Logotype(props) {
	return (
		<svg class={style.logotype} width="197" height="44" viewBox="0 0 197 44" {...props}>
			<path d="M105.469 39.6475C104.69 38.9215 104.278 37.9391 104.278 36.9141V20.684C104.278 18.8902 103.361 12.3982 94.9306 12.3982C86.4998 12.3982 84.4838 20.3851 84.4838 20.3851L85.4001 14.1066C85.4001 13.2951 84.5296 12.7826 83.7507 13.0816L73.5329 15.1317C72.7998 15.4306 72.6624 16.3703 73.3038 16.7974C74.9991 17.9933 76.0072 19.8298 76.0072 21.7945V35.9317C76.0072 37.5974 75.3199 39.1777 74.0828 40.3309L73.3955 40.9716C72.8915 41.6122 73.3955 42.5092 74.2202 42.5092H87.0038C87.8286 42.5092 88.3326 41.6549 87.8744 41.0143L86.4998 39.6475C85.8125 38.9215 85.4001 38.0245 85.4001 37.0422V26.0656C85.4001 24.528 85.7667 23.0331 86.5915 21.6664C87.462 20.2569 88.8366 18.8475 90.8527 18.8475C95.1597 18.8475 94.9764 23.6738 94.9764 23.6738V36.9568C94.9764 37.9391 94.564 38.9215 93.8309 39.6475L92.4563 40.9716C91.9981 41.6122 92.4563 42.5092 93.3269 42.5092H106.156C106.981 42.5092 107.485 41.6122 106.981 40.9716L105.469 39.6475Z" fill="currentColor" />
			<path d="M195.963 40.9288L194.405 39.4767C193.717 38.836 193.305 37.9818 193.305 37.0422V20.684C193.305 18.8902 192.389 12.3982 183.958 12.3982C177.314 12.3982 174.748 17.3526 174.153 19.4454C173.74 16.8828 172.411 12.3982 165.355 12.3982C156.924 12.3982 154.908 20.3851 154.908 20.3851L155.825 14.1066C155.825 13.2951 154.954 12.7826 154.175 13.0816L143.958 15.2171C143.133 15.4306 143.087 16.4557 143.728 16.8828C145.424 18.0787 146.432 19.9152 146.432 21.8799V35.9744C146.432 37.6401 145.745 39.2204 144.507 40.3736L143.82 41.0142C143.316 41.6549 143.82 42.5518 144.645 42.5518H157.428C158.253 42.5518 158.757 41.6976 158.299 41.057L157.612 40.3736C156.466 39.2204 155.825 37.7255 155.825 36.1452V25.6385C155.825 24.5707 156.054 23.5456 156.512 22.606C157.291 20.983 159.215 18.8475 161.69 18.8475C165.676 18.8475 165.355 23.6738 165.355 23.6738V37.1276C165.355 38.0245 164.989 38.8787 164.301 39.5194L162.789 40.9715C162.331 41.6122 162.789 42.5091 163.66 42.5091H176.489C177.314 42.5091 177.818 41.6122 177.314 40.9715L175.756 39.5194C175.069 38.8787 174.657 38.0245 174.657 37.0849V25.1259C174.657 23.5884 175.115 22.0935 176.077 20.8549C176.948 19.7444 178.139 18.8048 179.788 18.8048C184.095 18.8048 183.912 23.6311 183.912 23.6311V37.0849C183.912 37.9818 183.546 38.836 182.858 39.4767L181.346 40.9288C180.888 41.5695 181.346 42.4664 182.217 42.4664H195.046C195.963 42.4664 196.467 41.5695 195.963 40.9288Z" fill="currentColor" />
			<path d="M55.4806 12.0991H55.114C46.5 12.0991 39.2605 18.0359 39.2605 27.3041V28.0301C39.2605 37.2129 46.3167 43.1497 54.9307 43.1497H55.7555C64.3695 43.1497 71.4257 37.4692 71.4257 28.0301V27.3041C71.4257 18.164 64.0946 12.0991 55.4806 12.0991ZM55.2057 39.6474C51.2652 39.6474 49.6157 35.8889 49.6157 27.4749C49.6157 19.1464 51.4026 15.516 55.2057 15.516C59.0087 15.516 60.7956 19.0182 60.7956 27.4749C60.7956 35.8035 59.1461 39.6474 55.2057 39.6474Z" fill="currentColor" />
			<path d="M23.0401 16.5411H22.6278C27.393 16.5411 32.5248 14.3629 32.5248 8.55422C32.4789 3.25812 28.1261 0.481934 21.5739 0.524644H20.016L1.04681 0.481934C0.13042 0.481934 -0.327774 1.50699 0.267878 2.10493L1.13845 2.74559C2.78795 3.94149 3.75015 5.73533 3.75015 7.6573L3.79597 35.2056C3.79597 36.9567 3.01704 38.6224 1.64246 39.8183L0.542795 40.758C0.038781 41.3986 0.542795 42.3383 1.41336 42.3383H13.9221C13.9221 42.3383 19.0538 42.3383 21.7114 42.3383H21.7572C29.409 42.3383 35.8696 38.9641 35.8696 28.6282C35.8696 20.2569 30.0963 16.5411 23.0401 16.5411ZM17.9084 39.135C15.5257 39.135 13.9221 37.8964 13.9221 35.6754V35.2056V23.3321V21.4101V18.5485V16.3703V7.05936V6.37599C13.9221 4.71028 15.3425 3.38625 17.1294 3.38625C20.0161 3.25812 23.7732 3.64251 23.7732 9.06675C23.7732 15.2598 18.3666 15.5588 16.2589 15.7723C16.2589 15.7723 15.3425 15.815 15.3425 16.7546C15.3425 17.2245 15.8465 17.6089 16.213 17.6516C18.504 17.8651 24.9187 16.8401 24.9187 28.4146C24.9645 39.8183 17.9084 39.135 17.9084 39.135Z" fill="currentColor" />
			<path d="M141.117 39.8184L140.2 38.6225C139.421 37.5974 139.009 36.3588 139.009 35.1202V20.2569C139.009 20.0007 139.009 19.7017 139.009 19.4454C139.009 19.1038 139.055 18.7621 139.055 18.4204C139.101 17.8224 139.146 17.1818 139.238 16.5838L139.605 13.9785C139.696 13.4233 139.238 12.9534 138.688 12.9534C138.597 12.9534 138.505 12.9534 138.459 12.9534L127.554 14.6192C126.775 14.7473 126.5 15.6869 127.096 16.1567L127.646 16.5838C128.196 17.0964 128.608 17.6516 128.929 18.2496C129.341 19.0611 129.524 19.9153 129.524 20.8122V29.2689C129.524 30.8065 129.112 32.3013 128.333 33.6254C127.463 35.0348 126.134 36.4015 124.118 36.4015C119.719 36.4015 119.994 31.5753 119.994 31.5753V20.5986C119.994 19.1038 120.086 17.6089 120.269 16.1567L120.544 13.9785C120.635 13.3805 120.04 12.868 119.398 12.9534L108.493 14.6192C107.714 14.7473 107.439 15.6869 108.035 16.1567L108.585 16.5838C109.868 17.737 110.601 19.36 110.601 21.0257V34.6077C110.601 36.4015 111.517 42.8936 119.948 42.8936C127.096 42.8936 129.616 37.1703 130.257 35.4192C130.257 35.3765 130.349 35.3765 130.349 35.4192L129.616 41.4841C129.616 42.2102 130.349 42.7654 131.082 42.5946L140.384 41.1851C141.025 41.0143 141.392 40.3736 141.117 39.8184Z" fill="currentColor" />
		</svg>
	);
}
