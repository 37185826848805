import { h } from 'preact';
import { Elements, RichText } from 'prismic-reactjs';
import { linkResolver } from '../../prismic';
import Ellipse from '../../components/ellipse';
import style from './Quote.css';

function htmlSerializer(type, element, content, children, index) {
	switch (type) {
		case Elements.strong: return h(Ellipse, { key: index }, children);
		default: return null;
	}
}

export default function Quote(props) {
	return (
		<article class={style.quote}>
			{props && props.primary && props.primary.quote && (
				<RichText
					render={props.primary.quote}
					htmlSerializer={htmlSerializer}
					linkResolver={linkResolver}
				/>
			)}
		</article>
	);
}
