import { h } from 'preact';
import { useEffect } from 'preact/compat';
import { useLocation } from '../../lib/router';
import { route } from 'preact-router';
import { decode } from 'qss';
import Prismic from 'prismic-javascript';
import { apiEndpoint, linkResolver } from '../../prismic';

export default function Preview(props) {
	const location = useLocation();

	useEffect(() => {
		const { token } = decode(location.search.substring(1));

		if (token) {
			props.setPreviewToken(token);
			redirectToPreview(token);
		}
		else {
			route('/', true);
		}
	}, []);

	return null;
}

async function redirectToPreview(token) {
	const api = await Prismic.getApi(apiEndpoint);
	const url = await api.previewSession(token, linkResolver, '/');

	route(url, true);
}
