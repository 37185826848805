import createPage from '../../components/Page';

import Grid from './Grid';
import Heading from './Heading';
import Text from './Text';

const slices = {
	heading: {
		component: Heading,
		container: Grid
	},
	text: {
		component: Text,
		container: Grid
	}
};

const Legal = createPage({ slices });

export default Legal;
