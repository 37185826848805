import { h } from 'preact';
import {
	useState,
	useEffect,
	useRef
} from 'preact/hooks';
import cx from '../lib/cx';
import style from './Menu.css';

const menuItems = [
	{ url: '/', title: 'Home' },
	{ url: '/ventures', title: 'Ventures' },
	{ url: '/about', title: 'About' },
	{ url: '/contact', title: 'Contact' }
];

function MenuItem({ url, title, currentUrl, activeCallback, mouseoverCallback }) {
	const el = useRef(null);

	function handleMouseOver() {
		const rect = el.current.getBoundingClientRect();
		mouseoverCallback(rect);
	}

	const isActive = url === currentUrl;

	useEffect(() => {
		function measure() {
			const rect = el.current.getBoundingClientRect();
			activeCallback(rect);
		}

		if (isActive) {
			measure();

			// TODO throttle
			window.addEventListener('resize', measure);
			return () => {
				window.removeEventListener('resize', measure);
			};
		}
	}, [el, isActive, activeCallback]);

	return (
		<li ref={el} onMouseOver={handleMouseOver}>
			<a href={url}>{title}</a>
		</li>
	);
}

export default function Menu({ currentUrl, headerVisible }) {
	const [scroll, setScroll] = useState(0);
	const [menuOpen, setMenuOpen] = useState(false);
	const [hoverOffset, setHoverOffset] = useState(null);
	const [activeOffset, setActiveOffset] = useState(null);

	function handleMenuButtonClick() {
		setMenuOpen(!menuOpen);
	}

	function handleNavMouseLeave() {
		setHoverOffset(null);
	}

	function handleItemMouseOver(rect) {
		const { left, width } = rect;
		const { innerWidth } = window;
		const newOffset = (left + width / 2) / innerWidth * 100;

		setHoverOffset(newOffset);
	}

	function handleItemActive(rect) {
		const { left, width } = rect;
		const { innerWidth } = window;
		const newOffset = (left + width / 2) / innerWidth * 100;

		setActiveOffset(newOffset);
	}

	useEffect(() => {
		function handleScroll() {
			const { scrollY } = window;

			if (scrollY < scroll) {
				setMenuOpen(true);
			}
			else {
				setMenuOpen(false);
			}

			setScroll(scrollY);
		}

		window.addEventListener('scroll', handleScroll);
		return () => window.removeEventListener('scroll', handleScroll);
	}, [scroll]);

	return (
		<nav class={cx(
			style.menu,
			menuOpen && style.open,
			headerVisible && style.top
		)}
		>
			<div class={style.menuButton} onClick={handleMenuButtonClick} style={{ left: menuOpen ? `${hoverOffset || activeOffset}%` : '50%' }} />
			<ul onMouseLeave={handleNavMouseLeave}>
				{menuItems.map(({ url, title }) =>
					<MenuItem url={url} title={title} currentUrl={currentUrl} mouseoverCallback={handleItemMouseOver} activeCallback={handleItemActive} />
				)}
			</ul>
		</nav>
	);
}
