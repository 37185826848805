import { h } from 'preact';
import useCanvas from './canvas';
import style from './style';

export default function Canvas() {
	const { canvas, color } = useCanvas();

	return (
		<canvas class={style.canvas} style={{ '--color': color }} ref={canvas} />
	);
}
