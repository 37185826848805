import { h } from 'preact';
import cx from '../../lib/cx';
import { RichText } from 'prismic-reactjs';
import style from './Heading.css';

export default function Heading(props) {
	return (
		<header class={cx(style.heading, '--narrow')}>
			<RichText render={props.primary.heading} />
		</header>
	);
}
