import { useState, useEffect, useRef } from 'preact/compat';

export default function useTypewriter(str, { delay, onComplete }) {
	const [word, setWord] = useState('');
	const [nextWord, setNextWord] = useState('');
	const [erasing, setErasing] = useState(false);
	const [index, setIndex] = useState(0);
	const savedCallback = useRef(null);

	useEffect(() => {
		setNextWord(str);
	}, [str]);

	useEffect(() => {
		savedCallback.current = onComplete;
	}, [onComplete]);

	useEffect(() => {
		setErasing(true);
	}, [nextWord]);

	useEffect(() => {
		let id = setInterval(tick, delay);
		return () => clearInterval(id);

		function tick() {
			setIndex(index => {
				if (erasing) {
					if (index === 0) {
						setErasing(false);
						setWord(nextWord);
						if (typeof savedCallback.current === 'function') {
							savedCallback.current();
						}
					}
					else {
						return index - 1;
					}
				}
				else if (index === word.length) {
					clearInterval(id);
				}
				else {
					return index + 1;
				}
				return index;
			});
		}
	}, [erasing, delay, word, nextWord]);

	return word.slice(0, index);
}
