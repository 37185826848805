import { h } from 'preact';
import { useState, useCallback } from 'preact/hooks';
import FoundersCarousel from './FoundersCarousel';
import FoundersNav from './FoundersNav';
import FoundersList from './FoundersList';
import style from './Founders.css';

export default function Founders(props) {
	const [index, setIndex] = useState(0);

	const activate = useCallback(i => setIndex(i), []);

	return (
		<section class={style.founders}>
			<div class={style.mobile}>
				<FoundersCarousel items={props.items} />
			</div>

			<div class={style.desktop}>
				<div class={style.nav}>
					<FoundersNav items={props.items} activeIndex={index} setIndex={setIndex} />
				</div>
				<div class={style.list}>
					<FoundersList items={props.items} onInView={activate} />
				</div>
			</div>
		</section>
	);
}
