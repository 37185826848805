import { h } from 'preact';
import { useInView } from 'react-intersection-observer';
import style from './Image.css';

/**
 * TODO
 * 	- add logic to detect when image is loaded
 * 	- add load transition (fade?)
 * 	- remove background when image is loaded
 * 		(currently shows aliasing around border when rounded)
 */

const widths = [
	300,
	630,
	768,
	1024,
	1680,
	2048,
	3072,
	4096
];

const intersectionOptions = {
	rootMargin: '50%',
	triggerOnce: true
};

export default function Image({ url, dimensions, alt, aspect, sizeHint = 80, ...props }) {
	const [ref, inView] = useInView(intersectionOptions);

	const src = `${url}&w=${768}`;

	const srcset = widths.map(
		width => `${url}&w=${width} ${width}w`
	).join(', ');

	const sizes = [
		'(max-width: 768px) 100vw',
		`(min-width: 1600px) ${1600 * sizeHint / 100}px`,
		`${sizeHint}vw`
	].join(', ');

	const computedAspect = dimensions
		? (dimensions.height / dimensions.width)
		: 1;

	return (
		<div {...props}
			ref={ref}
			class={style.imageContainer}
			style={{
				'--aspect': aspect || computedAspect
			}}
		>
			{inView && (
				<img
					src={src}
					srcset={srcset}
					sizes={sizes}
					alt={alt}
				/>
			)}
		</div>
	);
}
