import { h } from 'preact';
import { useEffect } from 'preact/compat';
import { useTransition, animated } from 'react-spring';
import { Close, ArrowLeft, ArrowRight } from './Icons';
import style from './Modal.css';

export default function Modal({ open, onDismiss, onPrevious, onNext, children }) {
	useEffect(() => {
		if (open) {
			document.documentElement.style.overflow = 'hidden';
			// For Safari:
			document.body.style.overflow = 'hidden';
		}
		else {
			document.documentElement.style.overflow = 'initial';
			// For Safari:
			document.body.style.overflow = 'initial';
		}
	}, [open]);

	const transitions = useTransition(open, null, {
		from: { opacity: 0 },
		enter: { opacity: 1 },
		leave: { opacity: 0 }
	});

	return transitions.map(({ item, key, props }) =>
		item && (
			<animated.div key={key} class={style.modalWrapper} style={props}>
				<div class={style.modalClickCatcher} onClick={onDismiss} />
				<div class={style.modal}>
					<div class={style.modalCard}>
						<div class={style.controls}>
							<button class={style.closeButton} onClick={onDismiss}>
								<Close />
								<span class="visually-hidden">Close</span>
							</button>
							<div class={style.arrows}>
								<button class={style.leftButton} onClick={onPrevious}>
									<ArrowLeft />
									<span class="visually-hidden">Previous</span>
								</button>
								<button class={style.rightButton} onClick={onNext}>
									<ArrowRight />
									<span class="visually-hidden">Next</span>
								</button>
							</div>
						</div>
						<div class={style.contentWrapper}>
							<div class={style.contentInner}>
								{children}
							</div>
						</div>
					</div>
				</div>
			</animated.div>
		)
	);
}
