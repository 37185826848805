import { useEffect } from 'preact/hooks';

export default function useViewportHeight() {
	useEffect(() => {
		setHeight();

		window.addEventListener('resize', setHeight);
		return () => window.removeEventListener('resize', setHeight);
	}, []);
}

function setHeight() {
	let vh = window.innerHeight;
	document.documentElement.style.setProperty('--vh', `${vh}px`);
}
