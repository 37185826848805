import { h } from 'preact';
import { useState, useCallback } from 'preact/compat';
import { useTransition, animated, config } from 'react-spring';
import useCycle from '../../lib/use-cycle';
import useTypewriter from '../../lib/use-typewriter';
import { RichText } from 'prismic-reactjs';
import Image from '../../components/Image';
import Button from '../../components/Button';
import style from './Hero.css';

export default function Hero(props) {
	const { word, color, image } = useCycle(props.items, { delay: 3000 });
	// const [currentWord, setCurrentWord] = useState(word);
	const [currentColor, setCurrentColor] = useState(color);
	const [currentImage, setCurrentImage] = useState(image);
	// Only switch once previous word has been erased
	const onComplete = useCallback(() => {
		// setCurrentWord(word);
		setCurrentColor(color);
		setCurrentImage(image);
	}, [word, color, image]);
	const typingWord = useTypewriter(word, { delay: 100, onComplete });

	return (
		<article class={style.hero} style={{ '--color': currentColor }}>
			<div>
				<h1>
					{RichText.asText(props.primary.sentence)}
					&nbsp;
					<span class={style.wordWrapper}>
						<strong>
							{/* <span class={style.hiddenWord} aria-hidden="true">{currentWord}</span> */}
							{/* "people" is visually the longest word, use it to prevent jumps */}
							<span class={style.hiddenWord} aria-hidden="true">people</span>
							<span class={style.word}>{typingWord}</span>
						</strong>
					</span>
				</h1>
				<footer>
					<Button href="/about">Learn more <span class="visually-hidden">about Bonum</span></Button>
				</footer>
			</div>
			<aside>
				<HeroImage image={currentImage} />
			</aside>
		</article>
	);
}

function HeroImage({ image }) {
	const transitions = useTransition(image, item => item.url, {
		from: { opacity: 0 },
		enter: { opacity: 1 },
		leave: { opacity: 0 },
		config: config.molasses
	});

	return transitions.map(({ item, props, key }) => (
		<animated.div
			key={key}
			style={props}
		>
			<Image {...item} aspect={1} sizeHint={50} />
		</animated.div>
	));
}
