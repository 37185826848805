import createPage from '../../components/Page';

import Heading from '../../components/slices/Heading';

import Grid from './Grid';
import Person from './Person';
import Quote from './Quote';
import PersonModal from './PersonModal';

const slices = {
	heading: {
		component: Heading
	},
	person: {
		container: Grid,
		component: Person
	},
	quote: {
		container: Grid,
		component: Quote
	}
};

const modal = {
	component: PersonModal,
	getKey(item) {
		return item.uid;
	},
	getData(document) {
		return document
			&& document.data
			&& document.data.body
			&& document.data.body.filter(
				slice => slice.slice_type === 'person'
			).map(
				slice => slice && slice.primary && slice.primary.person
			);
	}
};

const About = createPage({ slices, modal });

export default About;
