import { h } from 'preact';
import { useLocation } from '../lib/router';
import { useInView } from 'react-intersection-observer';
import { RichText } from 'prismic-reactjs';
import cx from '../lib/cx';
import Logotype from './Logotype';
import Menu from './Menu';
import style from './Header.css';

// TODO more robust light mode

export default function Header(props) {
	const [ref, inView] = useInView();

	const location = useLocation();
	const currentUrl = location.pathname;
	const lightMode = /^\/legal/.test(currentUrl);

	return (
		<header ref={ref} class={cx(style.header, lightMode && style.lightMode)}>
			<Menu currentUrl={currentUrl} headerVisible={inView} />
			<div class={style.headerInner}>
				<h1>
					<a href="/">
						<span class="visually-hidden">Bonum</span>
						<Logotype />
					</a>
				</h1>
				<p>{RichText.asText(props.slogan || [])}</p>
			</div>
		</header>
	);
}
