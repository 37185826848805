import { h } from 'preact';
import style from './Grid.css';

export default function Grid({ children }) {
	return (
		<article class={style.grid}>
			{children}
		</article>
	);
}
