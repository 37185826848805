import { h } from 'preact';
import { useState, useEffect, useCallback } from 'preact/hooks';
import cx from '../lib/cx';
import Button from './Button.Button';
import style from './CookieBar.css';

const COOKIE_KEY = 'bonum_cookies_accepted';

export default function CookieBar() {
	const [shown, setShown] = useState(false);

	useEffect(() => {
		if (localStorage.getItem(COOKIE_KEY) !== 'true') {
			setTimeout(() => setShown(true));
		}
	}, []);

	const handleAccept = useCallback(() => {
		localStorage.setItem(COOKIE_KEY, 'true');
		setShown(false);
	}, []);

	return (
		<div class={cx(style.cookiebar, shown && style.shown)}>
			<p>This site uses cookies. Read more in our <a href="/privacy-policy">Privacy Policy</a></p>
			<Button onClick={handleAccept}>Accept cookies</Button>
		</div>
	);
}
