import { h } from 'preact';
import { useCallback } from 'preact/hooks';
import { useLocation } from '../lib/router';
import cx from '../lib/cx';
import Logo from './Logo';
import style from './Footer.css';

const ventures = [
	{ href: 'https://forum.eu', title: 'Forum' },
	{ href: 'https://www.kwara.com', title: 'Kwara' },
	{ href: 'https://www.polyteia.com', title: 'Polyteia' },
	{ href: 'https://themis.eu', title: 'Themis' },
];

const pages = [
	{ href: '/ventures', title: 'Ventures' },
	{ href: '/about', title: 'About' },
	{ href: '/contact', title: 'Contact' }
];

const legal = [
	{ href: '/legal/privacy-policy', title: 'Privacy Policy' },
	{ href: '/legal/imprint', title: 'Imprint' }
];

const scrollToTop = () => {
	window.scroll(0, 0);
};

function Menu({ items }) {
	const handleClick = useCallback(scrollToTop, []);

	return (
		<ul>
			{items.map(({ href, title }) => (
				<li>
					{/^https?:\/\//.test(href)
						? <a onClick={handleClick} href={href} target="_blank" rel="noopener noreferrer">{title}</a>
						: <a onClick={handleClick} href={href}>{title}</a>
					}
				</li>
			))}
		</ul>
	);
}

// TODO check font sizes for footer
// TODO more robust light mode

export default function Footer() {
	const location = useLocation();
	const currentUrl = location.pathname;
	const lightMode = /^\/legal/.test(currentUrl);
	const handleClick = useCallback(scrollToTop, []);

	return (
		<footer class={cx(style.footer, lightMode && style.lightMode)}>
			<h1>
				<a onClick={handleClick} href="/">
					<span class="visually-hidden">Bonum</span>
					<Logo />
				</a>
			</h1>
			<nav>
				<section>
					<h2>Ventures</h2>
					<Menu items={ventures} />
				</section>
				<section>
					<h2>Bonum</h2>
					<Menu items={pages} />
				</section>
				<section class={style.legal}>
					<Menu items={legal} />
				</section>
			</nav>
		</footer>
	);
}
