import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';
import Swiper from 'react-id-swiper';
import VentureCard from '../../components/VentureCard';
import style from './Ventures.css';

export default function VenturesCarousel(props) {
	const [swiper, updateSwiper] = useState(null);

	useEffect(() => {
		if (swiper) {
			setTimeout(() => swiper.update());
		}
	}, [swiper]);

	return (
		<section class={style.ventures}>
			{props.items && (
				<Swiper
					getSwiper={updateSwiper}
					slidesPerView={1}
					breakpoints={{
						768: {
							slidesPerView: 2
						},
						1200: {
							slidesPerView: 3
						}
					}}
				>
					{props.items.map(item => (
						<div>
							<VentureCard {...item.venture.data} />
						</div>
					))}
				</Swiper>
			)}
		</section>
	);
}
