import { h } from 'preact';
import { RichText } from 'prismic-reactjs';
import { linkResolver } from '../../prismic';
import Image from '../../components/Image';
import style from './PersonModal.css';

export default function PersonModal(props) {
	return (
		<div class={style.grid}>
			<div class={style.image}>
				{props.data && props.data.photo && (
					<Image {...props.data.photo} aspect={1} sizeHint={50} />
				)}
			</div>
			<div class={style.text}>
				{props.data && props.data.name && (
					<h2>{RichText.asText(props.data.name)}</h2>
				)}
				{props.data && props.data.role && (
					<h3>{props.data.role}</h3>
				)}
				{props.data && props.data.biography && (
					<RichText render={props.data.biography} linkResolver={linkResolver} />
				)}
			</div>
		</div>
	);
}
