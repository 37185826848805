import createPage from '../../components/Page';

import Heading from '../../components/slices/Heading';

import Grid from './Grid';
import TeamTeaser from '../../components/TeamTeaser';

const slices = {
	heading: {
		component: Heading
	},
	cards: {
		component: Grid
	},
	team_banner: {
		component: TeamTeaser
	}
};

const Contact = createPage({ slices });

export default Contact;
