import { h } from 'preact';
import { useCallback, useContext } from 'preact/hooks';
import { RichText } from 'prismic-reactjs';
import { ModalContext } from '../../components/Page';
import Image from '../../components/Image';
import style from './Person.css';

export default function Person(props) {
	const person = props
		&& props.primary
		&& props.primary.person;

	const setModal = useContext(ModalContext);
	const openModal = useCallback(() =>
		setModal(person && person.uid)
	, [person]);

	return (
		<article class={style.person} onClick={openModal}>
			<div class={style.image}>
				{person && person.data && person.data.photo && (
					<Image {...person.data.photo} aspect={1} sizeHint={50} />
				)}
			</div>

			<div class={style.text}>
				{person && person.data && person.data.name && (
					<p><strong>{RichText.asText(person.data.name)}</strong></p>
				)}
				{person && person.data && person.data.role && (
					<p>{person.data.role}</p>
				)}
			</div>
		</article>
	);
}
