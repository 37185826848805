import createPage from '../../components/Page';

import Heading from '../../components/slices/Heading';

import Hero from './Hero';
import Ventures from './Ventures';
import Founders from './Founders';

const slices = {
	hero: {
		component: Hero
	},
	heading: {
		component: Heading
	},
	ventures: {
		component: Ventures
	},
	founders: {
		component: Founders
	}
};

const Home = createPage({ slices });

export default Home;
