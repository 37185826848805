import { useState, useCallback } from 'preact/compat';
import useInterval from './use-interval';

export default function useCycle(arr, { delay }) {
	const [index, setIndex] = useState(0);

	useInterval(() => {
		const update = useCallback(
			index => (index + 1) % arr.length,
			[arr]
		);
		setIndex(update);
	}, delay);

	return arr[index];
}
