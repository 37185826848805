import { h } from 'preact';
import style from './Grid.css';

export default function Grid({ children }) {
	return (
		<section class={style.grid}>
			{children}
		</section>
	);
}
