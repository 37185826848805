import { h } from 'preact';
import { RichText } from 'prismic-reactjs';
import { linkResolver } from '../../prismic';
import Image from '../../components/Image';
import style from './FounderCardMobile.css';

export default function FounderCardMobile(props) {
	return (
		<div class={style.card}>
			<header class={style.cardHeader}>
				<Image {...props.data.photo} aspect={1} sizeHint={30} />

				<div class={style.cardHeaderText}>
					{props.data && props.data.name && (
						<h1>{RichText.asText(props.data.name)}</h1>
					)}
					{props.data && props.data.role && (
						<p class="h1">{props.data.role}</p>
					)}
				</div>
			</header>
			<blockquote>
				<RichText render={props.data.quote} linkResolver={linkResolver} />
			</blockquote>
		</div>
	);
}
