import './style';
import 'intersection-observer';
import smoothscroll from 'smoothscroll-polyfill';
import './analytics';
import App from './components/app';

if (typeof window !== 'undefined') {
	smoothscroll.polyfill();
}

export default App;
