import { h } from 'preact';

export function Close() {
	return (
		<svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect x="1.38867" width="33.391" height="1.96417" transform="rotate(45 1.38867 0)" fill="black" />
			<rect y="23.611" width="33.391" height="1.96417" transform="rotate(-45 0 23.611)" fill="black" />
		</svg>
	);
}

export function ArrowLeft() {
	return (
		<svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect x="11.2178" y="22.5952" width="15.8643" height="2" transform="rotate(-135 11.2178 22.5952)" fill="black" />
			<rect x="12.751" y="1.41422" width="16" height="2" transform="rotate(135 12.751 1.41422)" fill="black" />
			<rect x="22.751" y="12.5952" width="20" height="2" transform="rotate(-180 22.751 12.5952)" fill="black" />
		</svg>
	);
}

export function ArrowRight() {
	return (
		<svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect x="11.5342" y="3.05176e-05" width="15.8643" height="2" transform="rotate(45 11.5342 3.05176e-05)" fill="black" />
			<rect x="10.001" y="21.1811" width="16" height="2" transform="rotate(-45 10.001 21.1811)" fill="black" />
			<rect x="0.000976562" y="10" width="20" height="2" fill="black" />
		</svg>
	);
}
