import { h } from 'preact';
import { RichText, Link } from 'prismic-reactjs';
import { linkResolver } from '../prismic';
import style from './VentureCard.css';

export default function VentureCard(props) {
	return (
		<div class={style.card}>
			<header class={style.cardHeader}>
				{props.logo && (
					<h2>
						<img
							class={style.logo}
							src={props.logo.url}
							alt={props.title && RichText.asText(props.title)}
						/>
					</h2>
				)}
			</header>

			{props.description && (
				<p>{RichText.asText(props.description)}</p>
			)}

			<footer class={style.cardFooter}>
				{props.year && (
					<p>{props.year}</p>
				)}
				{props.link && (
					<p><VentureLink link={props.link} /></p>
				)}
			</footer>
		</div>
	);
}

function VentureLink(props) {
	const href = Link.url(props.link, linkResolver);
	const title = href.replace(/^https?:\/\//, '');

	return (
		<a href={href}>{title}</a>
	);
}
