const apiEndpoint = 'https://bonum-eu.prismic.io/api/v2';

const routes = [
	{
		url: () => '/',
		match: document => document.type === 'home',
		query(api, params, options) {
			return api.getSingle('home', options);
		}
	},
	{
		url: () => '/about',
		match: document => document.type === 'about',
		query(api, params, options) {
			return api.getSingle('about', options);
		}
	},
	{
		url: () => '/contact',
		match: document => document.type === 'contact',
		query(api, params, options) {
			return api.getSingle('contact', options);
		}
	},
	{
		url: () => '/ventures',
		match: document => document.type === 'ventures',
		query(api, params, options) {
			return api.getSingle('ventures', options);
		}
	},
	{
		url: document => `/ventures/${document.uid}`,
		match: document => document.type === 'venture',
		query(api, params, options) {
			return api.getByUID('venture', params.uid, options);
		}
	},
	{
		url: document => `/legal/${document.uid}`,
		match: document => document.type === 'legal',
		query(api, params, options) {
			return api.getByUID('legal', params.uid, options);
		}
	}
];

const fetchLinks = [
	'person.biography',
	'person.location',
	'person.name',
	'person.past_endeavours',
	'person.photo',
	'person.quote',
	'person.role',
	'venture.description',
	'venture.image',
	'venture.link',
	'venture.location',
	'venture.logo',
	'venture.text',
	'venture.title',
	'venture.year'
];

function linkResolver(document) {
	for (const route of routes) {
		if (route.match(document)) {
			return route.url(document);
		}
	}
	return '/';
}

module.exports = {
	apiEndpoint,
	routes,
	fetchLinks,
	linkResolver
};
