import { h } from 'preact';
import FounderCard from './FounderCard';

export default function FoundersList(props) {
	return props.items.map(({ founder }, index) => (
		<div id={founder.uid}>
			<FounderCard {...founder} index={index} onInView={props.onInView} />
		</div>
	));
}
