import { useEffect, useState } from 'preact/compat';
import { subscribers, getCurrentUrl } from 'preact-router';

export function useLocation() {
	const [url, setUrl] = useState(getCurrentUrl());
	useEffect(() => {
		subscribers.push(setUrl);
		return () => subscribers.splice(subscribers.indexOf(setUrl) >>> 0, 1);
	}, []);

	const [, pathname, search] = url.match(/^([^?]*)(\?.+)?$/);

	return {
		pathname,
		search
	};
}
