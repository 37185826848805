import { h } from 'preact';
import { useEffect } from 'preact/hooks';
import { RichText } from 'prismic-reactjs';
import { linkResolver } from '../../prismic';
import { useInView } from 'react-intersection-observer';
import Image from '../../components/Image';
import style from './FounderCard.css';

export default function FounderCard(props) {
	const [ref, inView] = useInView({ rootMargin: '0%', threshold: 1.0 });

	useEffect(() => {
		if (inView) {
			props.onInView(props.index);
		}
	}, [props.index, inView]);

	return (
		<div ref={ref}>
			<div class={style.card} data-open={inView}>
				<header class={style.cardHeader}>
					<Image {...props.data.photo} aspect={1} sizeHint={30} />

					<div class={style.cardHeaderText}>
						{props.data && props.data.past_endeavours && (
							<>
								<h4>Past endeavours</h4>
								<p class="h5">{RichText.asText(props.data.past_endeavours)}</p>
							</>
						)}
						{props.data && props.data.location && (
							<>
								<h4>Location</h4>
								<p class="h5">{props.data.location}</p>
							</>
						)}
					</div>
				</header>
				<blockquote>
					<RichText render={props.data.quote} linkResolver={linkResolver} />
				</blockquote>
			</div>
		</div>
	);
}
