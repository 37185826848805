import { h } from 'preact';
import { useCallback } from 'preact/hooks';
import { Elements, RichText } from 'prismic-reactjs';
import Ellipse from '../ellipse';
import style from './Heading.css';

export default function Intro(props) {
	const headingType = props
		&& props.primary
		&& props.primary.size
		|| 'h1';

	const htmlSerializer = useCallback((type, element, content, children, index) => {
		switch (type) {
			case Elements.paragraph: return h(headingType, { key: index }, children);
			case Elements.strong: return h(Ellipse, { key: index }, children);
			default: return null;
		}
	}, [headingType]);

	return (
		<header class={`${style.heading} --narrow`}>
			{props && props.primary && props.primary.heading && (
				<RichText
					render={props.primary.heading}
					htmlSerializer={htmlSerializer}
				/>
			)}
		</header>
	);
}
