import createPage from '../../components/Page';

import Heading from '../../components/slices/Heading';

import VenturesList from './VenturesList';
import TeamTeaser from '../../components/TeamTeaser';
import VentureModal from './VentureModal';

const slices = {
	heading: {
		component: Heading
	},
	ventures: {
		component: VenturesList
	},
	team_banner: {
		component: TeamTeaser
	}
};

const modal = {
	component: VentureModal,
	getKey(item) {
		return item.uid;
	},
	getData(document) {
		return document
			&& document.data
			&& document.data.body
			&& document.data.body.filter(
				slice => slice.slice_type === 'ventures'
			).reduce(
				(accumulator, slice) => [...accumulator, ...slice.items.map(i => i && i.venture)],
				[]
			);
	}
};

const Ventures = createPage({ slices, modal });

export default Ventures;
