import { h } from 'preact';
import { RichText } from 'prismic-reactjs';
import { linkResolver } from '../../prismic';
import Image from '../../components/Image';
import style from './VentureModal.css';

export default function VentureModal(props) {
	if (!props.data) {
		return null;
	}

	return (
		<div class={style.wrapper}>
			{props.data.logo && (
				<img
					class={style.logo}
					src={props.data.logo.url}
					alt={props.data.title && RichText.asText(props.data.title)}
				/>
			)}
			{props.data.description && (
				<div class={style.text}>
					<RichText render={props.data.description} linkResolver={linkResolver} />
				</div>
			)}
			{props.data.image && (
				<div class={style.image}>
					<Image {...props.data.image} sizeHint={50} />
				</div>
			)}
			{props.data.text && (
				<div class={style.text}>
					<RichText render={props.data.text} linkResolver={linkResolver} />
				</div>
			)}
		</div>
	);
}
