import { h } from 'preact';
import { useEffect, useRef, useCallback } from 'preact/compat';
import { useInView } from 'react-intersection-observer';
import { noise } from './utils';
import style from './style';

export default function Ellipse({ children }) {
	const spanElement = useRef(null);
	const canvasElement = useRef(null);

	const [inViewRef, inView] = useInView({
		rootMargin: '0%',
		threshold: 1.0,
		triggerOnce: true
	});

	const setSpanRef = useCallback(node => {
		spanElement.current = node;
		inViewRef(node);
	}, []);

	useEffect(() => {
		if (!inView) {
			return;
		}

		if (!canvasElement.current) {
			return;
		}

		const canvas = canvasElement.current;
		const ctx = canvas.getContext('2d');

		const { width, height } = canvas.getBoundingClientRect();
		const dpr = window.devicePixelRatio || 1;

		canvas.width = width * dpr;
		canvas.height = height * dpr;

		ctx.scale(dpr, dpr);
		ctx.lineWidth = Math.ceil(0.015 * (width + height));
		ctx.strokeStyle = `rgba(203, 176, 149, 0.75)`;

		const turns = 3;
		const samples = 50;
		const r1 = width / 2 - 5;
		const r2 = height / 2 - 5;
		const distortion = 0.0175 * (width + height);
		const center = [width / 2, height / 2];

		const points = [];

		for (let t = 0; t < turns; t++) {
			for (let i = 0; i < samples; i++) {
				const theta = 2 * Math.PI * i / samples;
				const point = [
					center[0] + r1 * Math.cos(theta) + distortion * noise(2 * Math.PI * t + theta),
					center[1] + r2 * Math.sin(theta) + distortion * noise(2 * Math.PI * t + theta)
				];
				points.push(point);
			}
		}

		setTimeout(draw, 300);

		function draw() {
			const drawnPoints = [];
			let index = 0;

			requestAnimationFrame(drawFrame);

			function drawFrame() {
				drawnPoints.push(points[index]);

				ctx.clearRect(0, 0, canvas.width, canvas.height);
				ctx.beginPath();
				ctx.moveTo(drawnPoints[0][0], drawnPoints[0][1]);

				for (const [x, y] of drawnPoints) {
					ctx.lineTo(x, y);
				}

				ctx.stroke();

				index++;

				if (index < points.length) {
					requestAnimationFrame(drawFrame);
				}
			}
		}
	}, [inView, canvasElement]);

	return (
		<span ref={setSpanRef} class={style.ellipse}>
			{children}
			<span class={style.canvasWrapper}>
				{inView && <canvas ref={canvasElement} class={style.canvas} />}
			</span>
		</span>
	);
}
